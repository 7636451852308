import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

const Features = () => {
    return (
        <div className="boxes-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-3 col-sm-6">
						<div className="single-box">
							<div className="icon">
                                <Icon.Server />
							</div>

							<h3>
								<Link to="/feature-details">
									Zero Configuration
								</Link>
							</h3>

							<p>Our solutions are delivered fully functioning and ready to use out-the-box, without any additional configuration.</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-f78acb">
							<div className="icon">
                                <Icon.Lock />
							</div>

							<h3>
								<Link to="/feature-details">
									Data Protection & Security
								</Link>
							</h3>

							<p>All your data will be encrypted and safely kept on servers not accessible to the public.</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-c679e3">
							<div className="icon">
                                <Icon.Globe />
							</div>

							<h3>
								<Link to="/feature-details">
									Worldwide Access
								</Link>
							</h3>

							<p>Anywhere you go, your projects go with you. Access your data anywhere, anytime!</p>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box bg-eb6b3d">
							<div className="icon">
                                <Icon.Star />
							</div>

							<h3>
								<Link to="/feature-details">
									Custom Made
								</Link>
							</h3>

							<p>Our systems are adjustable to the way you do your work, and our solutions won't get in the way of how you do business.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Features;
