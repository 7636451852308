import React, { Component } from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';
import AgencyShape1 from "../../assets/images/agency-image/agency-shape1.png";

class PricingStyleThree extends Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render() {
        return (
            <div id="pricing" className="pricing-area pb-50">
                <div className="container">
                    <div className="section-title st-fs-28">
                        <span className="sub-title">Pricing</span>
                        <h2>Our Pricing Plan</h2>
                        <div className="bar"></div>
                        <p>Flexible, adjustable and tailored for you</p>
                    </div>
                    
                    <div className="tab pricing-tab bg-color">
                        <ul className="tabs">
                            <li
                                className="current"
                                onClick={(e) => this.openTabSection(e, 'tab1')}
                            >
                                Monthly Plan
                            </li>
                            
                            <li onClick={(e) => this.openTabSection(e, 'tab2')}>
                                Yearly Plan <i data-feather="calendar"></i>
                            </li>
                        </ul>

                        <div className="tab_content">
                            <div id="tab1" className="tabs_item">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Basic</h3>
                                                <p>Get your business up <br /> and running</p>
                                            </div>

                                            <div className="price">
                                                500€ <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/contact" className="btn btn-primary">
                                                    Contact Us
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Up to 10 user accounts</li>
                                                <li><Icon.Check /> Your own deployment of Z-Max & Z-Mega</li>
                                                <li><Icon.Check /> Customer Service</li>
                                                <li><Icon.Check /> Up to 100 clients</li>
                                                <li><Icon.Check /> Unlimited jobs</li>
                                                <li><Icon.Check /> Up to 500GB of storage</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Pro</h3>
                                                <p>Most Popular <br/> with medium to large companies</p>
                                            </div>

                                            <div className="price">
                                                2000€ <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/contact" className="btn btn-primary">
                                                    Contact Us
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Up to 50 user accounts</li>
                                                <li><Icon.Check /> Your own deployment of Z-Max & Z-Mega</li>
                                                <li><Icon.Check /> Customer Service</li>
                                                <li><Icon.Check /> Up to 1000 clients</li>
                                                <li><Icon.Check /> Unlimited jobs</li>
                                                <li><Icon.Check /> Up to 10TB of storage</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Custom</h3>
                                                <p>Contact us for custom pricing</p>
                                            </div>

                                            <div className="price">
                                                ?€ <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/contact" className="btn btn-primary">
                                                    Contact Us
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Anything you need...</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="tab2" className="tabs_item">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Basic</h3>
                                                <p>Get your business up <br /> and running</p>
                                            </div>

                                            <div className="price">
                                                5000€ <span>/y</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/contact" className="btn btn-primary">
                                                    Contact Us
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Up to 10 user accounts</li>
                                                <li><Icon.Check /> Your own deployment of Z-Max & Z-Mega</li>
                                                <li><Icon.Check /> Customer Service</li>
                                                <li><Icon.Check /> Up to 100 clients</li>
                                                <li><Icon.Check /> Unlimited jobs</li>
                                                <li><Icon.Check /> Up to 500GB of storage</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Pro</h3>
                                                <p>Most Popular <br/> with medium to large companies</p>
                                            </div>

                                            <div className="price">
                                                20000€ <span>/y</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/contact" className="btn btn-primary">
                                                    Contact Us
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Up to 50 user accounts</li>
                                                <li><Icon.Check /> Your own deployment of Z-Max & Z-Mega</li>
                                                <li><Icon.Check /> Customer Service</li>
                                                <li><Icon.Check /> Up to 1000 clients</li>
                                                <li><Icon.Check /> Unlimited jobs</li>
                                                <li><Icon.Check /> Up to 10TB of storage</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Custom</h3>
                                                <p>Contact us for custom pricing</p>
                                            </div>

                                            <div className="price">
                                                ?€ <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="/contact" className="btn btn-primary">
                                                    Contact Us
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check /> Anything you need...</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shape Image */}
                <div className="shape9 mobile-dnone">
                    <img src={AgencyShape1} alt="image" />
                </div>
            </div>
        );
    }
}

export default PricingStyleThree;